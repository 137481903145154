import { PanelPlugin, SetFieldConfigOptionsArgs } from '@grafana/data';
import { TrajPanel } from './TrajPanel';
import { GraphFieldConfig } from '@grafana/ui';
import { defaultGraphConfig, getGraphFieldConfig } from 'panels/log-plot-panel/copiedFromAlpha75/timeSeriesConfig';
import { LogPlotFieldConfig } from 'panels/log-plot-panel/module';
import { TrajPanelOptions } from './types';

//TODO: duplicate with trackinfo in Calculation Utils, to be merged
export type TrackInfo = { Offset: number; TrackWidth: number; Position: 'Inner' | 'Outer' };
export interface Traj3DPlotFieldConfig extends GraphFieldConfig {
  //   fontSize: number;
}
export const defaultTrajFieldConfig: Traj3DPlotFieldConfig = {
  ...defaultGraphConfig,

  //   fontSize: 16,
};

function getTrajFieldConfig(): SetFieldConfigOptionsArgs<Traj3DPlotFieldConfig> {
  let fc = getGraphFieldConfig(defaultTrajFieldConfig as LogPlotFieldConfig);
  let oldUseCustomConf = fc.useCustomConfig!;
  fc.useCustomConfig = (builder) => {
    oldUseCustomConf(builder);

    // builder.addNumberInput({
    //   path: 'fontSize',
    //   name: 'Font Size',
    //   defaultValue: defaultTraj3DPlotFieldConfig.fontSize,
    // });
  };
  return fc as any;
}

export const plugin = new PanelPlugin<TrajPanelOptions, Traj3DPlotFieldConfig>(TrajPanel)
  .useFieldConfig(getTrajFieldConfig())
  .setPanelOptions((builder) => {
    builder

      .addTextInput({
        path: 'curve1Properties.curveTitle',
        name: 'Trajectory 1 Title',
        // description: 'Trajectory 1 Title',
        defaultValue: 'Traj1',
      })

      .addColorPicker({
        path: 'curve1Properties.color',
        name: 'Trajectory 1 Color',
        // description: 'Trajectory 1 Color',
        defaultValue: 'blue',
      })

      .addTextInput({
        path: 'curve2Properties.curveTitle',
        name: 'Trajectory 2 Title',
        // description: 'Trajectory 2 Title',
        defaultValue: 'Traj2',
      })

      .addColorPicker({
        path: 'curve2Properties.color',
        name: 'Trajectory 2 Color',
        // description: 'Trajectory 2 Color',
        defaultValue: 'red',
      })

      .addSelect({
        path: 'xAxisProperty',
        name: 'xAxis',
        settings: {
          options: [
            { value: 'vertSect', label: 'vertSect' },
            { value: 'tvd', label: 'tvd' },
            { value: 'dispNs', label: 'dispNs' },
            { value: 'dispEw', label: 'dispEw' },
          ],
        },
      })
      .addSelect({
        path: 'yAxisProperty',
        name: 'yAxis',
        settings: {
          options: [
            { value: 'vertSect', label: 'vertSect' },
            { value: 'tvd', label: 'tvd' },
            { value: 'dispNs', label: 'dispNs' },
            { value: 'dispEw', label: 'dispEw' },
          ],
        },
      });
  });
